$(document).ready(function(){
  let $myAccount = $('.links-my-account');
  let $group1Wrapper = $('.links-group1-wrapper');

  var Topnav = (function(){
    var getPageWidth = function(){
      return $(window).outerWidth();
    };

    var Takeover = (function(){
      var $el = $('.acpv-header'),
      expanded = false;

      var getStatus = function(){
        return expanded;
      };

      var expand = function(){
        $el.css('height', '100vh');
        expanded = true;
      };

      var shrink = function(){
        $el.css('height', '');
        expanded = false;
      };

      var toggle = function(){
        if (getStatus()){
            shrink();
        } else {
            expand();
        }
      };

      return {
          expand,
          shrink,
          toggle,
          getStatus,
      }
    })();

    function LinksGroupBase($el, preShrink){
      let expanded = false;
      let scrollTop = 0;
      let bodyPosition = "";
      let getStatus = function(){
          return expanded;
      };

      let expand = function(){
        // The body loses its scroll position when set to position: fixed.
        // This is used to restore the scroll position when the links modal is shrunk.
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        $el.css('display', 'block');
        $myAccount.css('display', 'none');
        if (getPageWidth() > 1023) {
          $group1Wrapper.css('display', 'none');
        }
        // Setting the position to fixed prevents scrolling on the main content when the links modal is expanded.
        bodyPosition = $(document.body).css("position");
        $(document.body).css({"position": "fixed"});
        expanded = true;
      };

      let shrink = function(){
        preShrink();
        if (expanded) {
          $(document.body).css({"position": bodyPosition});
          document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
          expanded = false;
        }
      };

      let toggle = function(){
        if(getStatus()){
          shrink();
        } else {
          expand();
        }
      };

      return {
        expand,
        shrink,
        toggle,
      }
    };

    var LinksGroup1 = (function(){
      let $el = $('.links-group1-wrapper');
      let shrink = function(){
        if (getPageWidth() > 1023) {
          $el.css('display', 'block');
          $myAccount.css('width', 'auto');
          $group1Wrapper.css('display', '');
        } else {
          $el.css('display', 'none');
          $myAccount.css('display', '');
          $myAccount.css('width', '');
        }
      };
      return LinksGroupBase($el, shrink);
    })();

    var LinksGroup2 = (function(){
      let $el = $('.links-group2-wrapper');
      let shrink = function(){
        $group1Wrapper.css('display', '');
        $el.css('display', 'none');
        $el.css('height', '0');
        $myAccount.css('display', '');
      };
      return LinksGroupBase($el, shrink);
    })();

    var LinksGroup3 = (function(){
      let $el = $('.links-group3-wrapper');
      let shrink = function() {
        $el.css('display', 'none');
        $el.css('height', '0');
      }
      let reset = function() {
        var linkGroup3IsActive = $('.acpv-header .links-group3-wrapper').css('display') !== 'none';

        if (linkGroup3IsActive) {
            LinksGroup3.toggle();
        }
      }
      return { ...LinksGroupBase($el, shrink), reset };
    })();

    var MyAccountIconBtn = (function() {
      var $el = $('#myAccountIconBtn');
      var $hamburger = $('.acpv-header .hamburger');

      var toggle = function(){
        if (Takeover.getStatus() === true) {
          $hamburger.find('.acpv-icon').removeClass('off').addClass('on');
        } else {
          $hamburger.find('.acpv-icon').removeClass('on').addClass('off');
        }
      };

      $el.on('click', function() {
        Takeover.toggle();
        LinksGroup3.toggle();
        toggle();
      });

      return {
        toggle,
      }
    })();


    // Function to trap focus within the header element
    function trapFocus() {
      const container = $('#main-nav');
      const focusableElements = container.find('a[href]');
      const firstElement = focusableElements.first();
      const lastElement = focusableElements.last();

      container.on('keydown', function(event) {
          console.log('tab on', $(this))
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            if (document.activeElement === firstElement[0]) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement[0]) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      });
    }

    const hideElement = () => {
      $('.acpv-main').hide();
      $('.acpv-footer').hide();
    }

    const showElement = () => {
      $('.acpv-main').show();
      $('.acpv-footer').show();
    }

    // Function to trap focus within the header element
    function trapFocus() {
      const container = $('#main-nav');
      const focusableElements = container.find('a[href]');
      const firstElement = focusableElements.first();
      const lastElement = focusableElements.last();

      container.on('keydown', function(event) {
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            if (document.activeElement === firstElement[0]) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement[0]) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      });
    }

    var Hamburger = (function(){
      var $el = $('.acpv-header .hamburger');

      var toggle = function(){
        if (Takeover.getStatus() === true) {
          hideElement();
          $el.find('.acpv-icon').removeClass('off').addClass('on');
          $el.attr('aria-expanded', 'true');
          $('.disclaimers').attr('aria-hidden', 'true');
        } else {
          showElement();
          $('.disclaimers').attr('aria-hidden', 'false');
          $el.find('.acpv-icon').removeClass('on').addClass('off');
          $el.attr('aria-expanded', 'false');
        }
      };

      $el.on('keydown', function(e) {
        if (e.key == "Enter") {
          $el.trigger('click');
        }
      });

      $el.on('click', function(){
        var linkGroup3IsActive = $('.acpv-header .links-group3-wrapper').css('display') !== 'none';

        Takeover.toggle();
        if (linkGroup3IsActive) {
          LinksGroup3.toggle();
        } else {
          // if laptop, then the hamburger shows/hides different content
          if (getPageWidth() >= 1024 && getPageWidth() <=1220) {
            LinksGroup2.toggle();
          } else {
            LinksGroup1.toggle();
          };
        }
        // change icon accordingly
        toggle();
      })

      return {
          toggle,
      }
    })();

    var Dropdown = (function() {
      var $trigger = $('.acpv-header .dropdown-toggle');
      var $parent = $trigger.parent('.dropdown');

      var toggle = function() {
        $parent.toggleClass('open');

        var isActive = $parent.hasClass('open')

        if (isActive) {
          $trigger.attr('aria-expanded', 'true');
        } else {
          $trigger.attr('aria-expanded', 'false');
        }
      }

      var reset = function() {
        $parent.removeClass('open');
      }

      $trigger.on('click', function() {
          toggle();
      });

      return {
          reset,
          toggle
      }

    })();

    var FavCount = function() {
      var wishlistData = localStorage.getItem('WISHLIST_VEHICLE') ? JSON.parse(localStorage.getItem('WISHLIST_VEHICLE')) : null;
      var favCount = wishlistData ? wishlistData.count : 0;
      var $link = $('.acpv-header .fav-link');
      var $count = $link.find('.fav-count');

      var init = function init() {
        if (favCount && parseInt(favCount, 10) > 0) {
          $count.text(favCount);
          $link.addClass('has-count');
        } else {
          $count.text('');
          $link.removeClass('has-count');
        }
      }

      return {
        init: init
      }
    }();

    // Init fav count
    FavCount.init();

    var reset = function(){
      Takeover.shrink();
      LinksGroup1.shrink();
      if (getPageWidth()<=1200) {
        LinksGroup2.shrink();
      }
    };

    // reseting when window is resized
    $(window).resize(function(){
      reset();
      Hamburger.toggle();
      Dropdown.reset();
      LinksGroup3.reset();
    })

    $('.acpv-header [data-logout-cta]').on('click', function() {
      window.location.pathname = '/logout';
    });

    // Language Selector
    var currentHostName = window.location.hostname;
    $('.language-selector a').on('click', function(){
      if (currentHostName == "es.acuracertified.com"){
        document.location.href = "https://www.acuracertified.com"
      } else {
        document.location.href = "https://es.acuracertified.com/"
      }
    })

    if (currentHostName == "es.acuracertified.com"){
      $('.language-selector a').text('English')
    }
  })();
});
